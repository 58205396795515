
<template>
  <div class="row">
    <div class="col-12">
      <h5 class="mb-3">Add New User</h5>
      <div class="">
        <user-create-form />
      </div>
    </div>
  </div>
</template>
<script>

import UserCreateForm from '@/components/forms/UserCreateForm.vue'

export default {
  components:{
    UserCreateForm
  },
  methods: {

  },
}
</script>
